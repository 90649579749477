<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          routeName="LANDING_BLOG_NEWS_REGISTER"/>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import NewsListViewModel from '@/views/landing/blog/news/viewModel/NewsListViewModel'

export default {
  name:'NewsList',
  components:{
    PageWithLayout,
    Board,
    Button,
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
  },
  data(){
    return{
      viewModel: new NewsListViewModel(),
    }
  },
}
</script>